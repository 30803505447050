 import React, { useState } from "react";
import axios from "axios";
import { server } from "../../server";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../Assests/image/bg.jpeg";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer"; 
import LoadingButton from "../Layout/LoadingButton"; 
const ForgotPasswordSeller = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
 const { t } = useTranslation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await axios.post(`${server}/shop/forgot-password-seller`, { email });
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong");
    }

    setLoading(false);
  };

  return (
    <div><Header />
    <div
          className="flex items-center justify-center min-h-screen bg-cover bg-center"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
    <div className="w-full max-w-md bg-white bg-opacity-10 backdrop-blur-lg
       border border-white/20 p-8 rounded-lg shadow-lg">
     <h2 className="text-center text-2xl font-bold text-white mb-6">

{t("ForgotPassword")}

</h2>
      <form className="space-y-5" onSubmit={handleSubmit}>
      <div>

<label className="block text-sm font-medium text-white">
  {t("Email")}
</label> 
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
            className="w-full px-4 py-3 rounded-lg bg-white bg-opacity-20 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-white"
        />
        </div>
          <LoadingButton isSending={loading} textButton={t("ResetPassword")} />
          <div className="text-center text-white">
            <span>{t("RememberedYourPassword")}</span> 
            <Link to="/shop-login" className="text-white-400 pl-2 no-underline hover:text-black">
              {t("login")}
            </Link>
          </div>
        </form>
      </div>
    </div> <Footer /></div> 
  );
};

export default ForgotPasswordSeller;
