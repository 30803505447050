import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const StatsSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [visitors, setVisitors] = useState(0);
  const [purchases, setPurchases] = useState(0);
  const [reviews, setReviews] = useState(0);
  const { t } = useTranslation();
  
  const targetNumbers = {
    visitors: 1000,
    purchases: 1000,
    reviews: 95,
  };

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Start animation when section enters viewport
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Trigger when 50% of the section is visible
    });

    const element = document.getElementById("stats-section");
    if (element) observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        setVisitors((prev) => (prev < targetNumbers.visitors ? prev + 40 : targetNumbers.visitors));
        setPurchases((prev) => (prev < targetNumbers.purchases ? prev + 2 : targetNumbers.purchases));
        setReviews((prev) => (prev < targetNumbers.reviews ? prev + 1 : targetNumbers.reviews));
      }, 50); // Adjust animation timing for smoothness

      return () => clearInterval(interval);
    }
  }, [isVisible]);

  return (
    <section
      id="stats-section"
      className="w-full bg-gray-100 py-10 text-center rounded-lg shadow-lg"
    >
      <div className="container mx-auto px-6">
        <h2 className="text-2xl md:text-4xl font-extrabold mb-5">
          {t("explore")}
        </h2>
        <p className="text-lg md:text-xl max-w-2xl mx-auto mb-8">
          {t("intro") }
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
          <div>
            <h3 className="text-4xl font-bold text-orange-500">{visitors}+</h3>
            <p className="text-lg text-gray-600">{t("visitors")}</p>
          </div>
          <div>
            <h3 className="text-4xl font-bold text-orange-500">{purchases}+</h3>
            <p className="text-lg text-gray-600">{t("purchases")}</p>
          </div>
          <div>
            <h3 className="text-4xl font-bold text-orange-500">{reviews}%</h3>
            <p className="text-lg text-gray-600">{t("reviews")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatsSection;