import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Importer i18next
import { backend_url } from "../../server";
import { FaFacebookF, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { t } from "i18next";

const BlogCard = ({ blog }) => {
  const { i18n } = useTranslation(); // Obtenir la langue actuelle
  const lang = i18n.language || "fr"; // Utiliser "fr" par défaut

  // Récupérer le titre et le contenu dans la langue active avec une valeur par défaut
  const title = blog.title[lang] || blog.title.fr || ""; // Utiliser 'fr' ou une chaîne vide si non défini
  const content = blog.content[lang] || blog.content.fr || ""; // Utiliser 'fr' ou une chaîne vide si non défini
  const category = blog.category?.[lang] || blog.category?.fr || "";

  return (
    <div className="bg-white shadow-lg  overflow-hidden w-72 h-auto transform transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105">
    {/* Image du blog avec catégorie et titre en superposition */}
    <div className="relative">
      <img
        src={`${backend_url}${blog.image}`}
        alt={title} // Utiliser le titre pour l'attribut alt
        className="w-full h-40 object-cover"
      />
        {/* Catégorie */}
        {category && (
          <div className="absolute top-3 left-3 bg-blue-600 text-white text-xs px-3 py-1 rounded-full">
            {category}
          </div>
        )}
        {/* Titre en bas de l'image */}
        <div className="absolute bottom-3 left-3 right-3 bg-black bg-opacity-50 text-white text-base font-semibold italic p-1 rounded-md">
          {title}
        </div>
      </div>
      
      {/* Contenu */}
      <div className="p-3">
        <p className="text-xs text-gray-600 mt-2">
          {content.length > 80 ? content.slice(0, 80) + "..." : content}
        </p>
        
        {/* Lien vers la page du blog et icônes sociales */}
        <div className="mt-3 flex justify-between items-center">
          <Link to={`/get-blog/${blog._id}`} className="text-black-500 text-xs font-semibold hover:">
            {t("Lire plus")}
          </Link>
          <div className="flex gap-2 text-black-500 text-xs">
            <FaInstagram className="hover:text-black-600 cursor-pointer" />
            <FaFacebookF className="hover:text-black-600 cursor-pointer" />
            <FaLinkedin className="hover:text-black-600 cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
