// import { React, useState } from "react";
// import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
// import styles from "../../styles/styles";
// import { Link, useNavigate } from "react-router-dom";
// import { RxAvatar } from "react-icons/rx";
// import axios from "axios";
// import { server } from "../../server";
// import { toast } from "react-toastify";
// import LoadingButton from "../Layout/LoadingButton";

// const Singup = () => {
//   const [email, setEmail] = useState("");
//   const [name, setName] = useState("");
//   const navigate = useNavigate();
//   const [password, setPassword] = useState("");
//   const [visible, setVisible] = useState(false);
//   const [avatar, setAvatar] = useState(null);
//   const [loading, setLoading] = useState(false);

//   const handleFileInputChange = (e) => {
//     const file = e.target.files[0];
//     setAvatar(file);
//   };

//   const handleSubmit = async (e) => {
//     setLoading(true);
//     e.preventDefault();
//     const config = { headers: { "Content-Type": "multipart/form-data" } };

//     const newForm = new FormData();

//     newForm.append("file", avatar);
//     newForm.append("name", name);
//     newForm.append("email", email);
//     newForm.append("password", password);

//     axios
//       .post(`${server}/user/create-user`, newForm, config)
//       .then((res) => {
//         setLoading(false);
//         toast.success("Login Success!");
//         navigate("/");
//         window.location.reload(true);
//       })
//       .catch((error) => {
//         setLoading(false);
//         toast.error(error.response.data.message);
//       });
//   };

//   return (
//     <>
//       <div className=" bg-white flex flex-col  py-12 justify-center ">
//         <div className="sm:mx-auto sm:w-full sm:max-w-md bg-white rounded-md sm:border-solid  border border-gray-200 border-none  py-8 px-8  ">
//           <div className=" w-full flex justify-center items-center mb-4 ">
//             <h2 className=" text-center text-[20px] capitalize font-[700] text-gray-900">
//               Register as a new user
//             </h2>
//           </div>
//           <form className="space-y-6" onSubmit={handleSubmit}>
//             <div>
//               <label
//                 htmlFor="email"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 Full Name
//               </label>
//               <div className="mt-1">
//                 <input
//                   type="text"
//                   name="text"
//                   autoComplete="name"
//                   required
//                   value={name}
//                   onChange={(e) => setName(e.target.value)}
//                   className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                 />
//               </div>
//             </div>

//             <div>
//               <label
//                 htmlFor="email"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 Email address
//               </label>
//               <div className="mt-1">
//                 <input
//                   type="email"
//                   name="email"
//                   autoComplete="email"
//                   required
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                 />
//               </div>
//             </div>

//             <div>
//               <label
//                 htmlFor="password"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 Password
//               </label>
//               <div className="mt-1 relative">
//                 <input
//                   type={visible ? "text" : "password"}
//                   name="password"
//                   autoComplete="current-password"
//                   required
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                   className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                 />
//                 {visible ? (
//                   <AiOutlineEye
//                     className="absolute right-2 top-2 cursor-pointer"
//                     size={25}
//                     onClick={() => setVisible(false)}
//                   />
//                 ) : (
//                   <AiOutlineEyeInvisible
//                     className="absolute right-2 top-2 cursor-pointer"
//                     size={25}
//                     onClick={() => setVisible(true)}
//                   />
//                 )}
//               </div>
//             </div>

//             <div>
//               <label
//                 htmlFor="avatar"
//                 className="block text-sm font-medium text-gray-700"
//               ></label>
//               <div className="mt-2 flex items-center">
//                 <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
//                   {avatar ? (
//                     <img
//                       src={URL.createObjectURL(avatar)}
//                       alt="avatar"
//                       className="h-full w-full object-cover rounded-full"
//                     />
//                   ) : (
//                     <RxAvatar className="h-8 w-8" />
//                   )}
//                 </span>
//                 <label
//                   htmlFor="file-input"
//                   className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
//                 >
//                   <span>Upload a file</span>
//                   <input
//                     type="file"
//                     name="avatar"
//                     id="file-input"
//                     accept=".jpg,.jpeg,.png"
//                     onChange={handleFileInputChange}
//                     className="sr-only"
//                   />
//                 </label>
//               </div>
//             </div>

//             <div>
//               <LoadingButton
//                 isSending={loading}
//                 textButton={"Create Account "}
//               />
//             </div>
//             <div
//               className={`${styles.noramlFlex} w-full justify-center items-center `}
//             >
//               <h4>Already have an account?</h4>
//               <Link to="/login" className="text-blue-600 pl-2">
//                 Sign In
//               </Link>
//             </div>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Singup;
import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { RxAvatar } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import LoadingButton from "../Layout/LoadingButton";
import backgroundImage from "../../Assests/image/bg.jpeg"; 
import { useTranslation } from "react-i18next"; 
const Signup = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(false);
const { t } = useTranslation();
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const newForm = new FormData();
    newForm.append("file", avatar);
    newForm.append("name", name);
    newForm.append("email", email);
    newForm.append("password", password);

    axios
      .post(`${server}/user/create-user`, newForm, config)
      .then((res) => {
        setLoading(false);
        alert(t("alertMessage")); // Use the translated message here
        toast.success("Signup Successful!");
        navigate("/");
        window.location.reload(true);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  return (
     <div
          className="flex items-center justify-center
           min-h-screen bg-cover bg-center"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
    <div  className="w-full max-w-md bg-white bg-opacity-10 backdrop-blur-lg border border-white/20 p-8 rounded-lg shadow-lg">
      {/* <div className="bg-white/10 backdrop-blur-md shadow-lg border border-white/20 rounded-lg p-8 w-96 text-center"> */}
        <h2 className="text-center text-2xl font-bold text-white mb-6">
          {t("SignUp")}</h2>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div className="relative">
          <label className="block text-sm font-medium text-white">
              {t("Name")}
            </label>
            <input
              type="text"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
             class="w-full px-4 py-3 rounded-lg bg-white bg-opacity-20 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-white"
            />
          
          </div>
          <div className="relative">
          <label className="block text-sm font-medium text-white">
              {t("Email")}
            </label>
          
            <input
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              class="w-full px-4 py-3 rounded-lg bg-white bg-opacity-20 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-white"
            />
         
          </div>
          <div className="relative">
          <label className="block text-sm font-medium text-white">
              {t("Password")}
            </label>
         
            <input
              type={visible ? "text" : "password"}
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              class="w-full px-4 py-3 rounded-lg bg-white bg-opacity-20 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-white"
            />
            {visible ? (
              <AiOutlineEye className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white cursor-pointer" size={20} onClick={() => setVisible(false)} />
            ) : (
              <AiOutlineEyeInvisible className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white cursor-pointer" size={20} onClick={() => setVisible(true)} />
            )}
           
          </div>
           <div className="flex justify-between text-sm text-white">
                      <label className="flex items-center">
                        <input type="checkbox" className="mr-2" />
                        {t("RememberMe")}
                      </label>
                      <Link to="/forgot-password" className="no-underline hover:text-black">
                        {t("Forgotyourpassword")}?
                      </Link>
                    </div>
          {/* <button className="w-full bg-white text-black font-semibold py-2 rounded-md transition hover:bg-white/20 hover:text-white border-2 border-transparent hover:border-white">
            {loading ? "Signing up..." : "Sign Up"}
          </button> */}
           <LoadingButton isSending={loading}
           textButton={t("SignUp")}  />
          <div className="text-center text-white">
          <span>{t("Alreadyhaveanaccount")}</span>
           <Link to="/login" className="text-white-400 pl-2  no-underline hover:text-black">
           {t("SignIn")}
           </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
