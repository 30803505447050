// import { React, useState } from "react";
// import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
// import styles from "../../styles/styles";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { server } from "../../server";
// import { toast } from "react-toastify";
// import { useTranslation } from "react-i18next";
// import LoadingButton from "../Layout/LoadingButton";
// import posthog from "posthog-js";  // Importer PostHog

// const Login = () => {
//   const navigate = useNavigate();
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [visible, setVisible] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const { t } = useTranslation();

//   const handleSubmit = async (e) => {
//     setLoading(true);
//     e.preventDefault();

//     await axios
//       .post(
//         `${server}/user/login-user`,
//         {
//           email,
//           password,
//         },
//         { withCredentials: true }
//       )
//       .then((res) => {
//         setLoading(false);
//         toast.success(t("LoginSuccess"));
//         // Identifier l'utilisateur dans PostHog après connexion réussie
//         const user = res.data.user; // Supposons que l'API renvoie un objet 'user'
//         posthog.identify(user.id, { 
//           email: user.email, 
//           name: user.name 
//         });
//         navigate("/");
//         window.location.reload(true);
//       })
//       .catch((err) => {
//         setLoading(false);
//         toast.error(err.response.data.message);
//       });
//   };

//   return (
//     <>
//       <div className=" flex flex-col justify-center py-12 bg-white  ">
//         <div className="sm:mx-auto sm:w-full sm:max-w-md bg-white rounded-md sm:border-solid  border border-gray-200 border-none  py-8 px-8  ">
//           <div className=" w-full flex justify-center items-center mb-4 ">
//             <h2 className=" text-center text-[20px] capitalize font-[700] text-gray-900">
//               {t("Logintoyouraccount")}
//             </h2>
//           </div>
//           <form className=" space-y-5 " onSubmit={handleSubmit}>
//             <div>
//               <label
//                 htmlFor="email"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 {t("Email")}
//               </label>
//               <div className="mt-1">
//                 <input
//                   type="email"
//                   name="email"
//                   autoComplete="email"
//                   required
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                 />
//               </div>
//             </div>
//             <div>
//               <label
//                 htmlFor="password"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 {t("Password")}
//               </label>
//               <div className="mt-1 relative">
//                 <input
//                   type={visible ? "text" : "password"}
//                   name="password"
//                   autoComplete="current-password"
//                   required
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                   className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                 />
//                 {visible ? (
//                   <AiOutlineEye
//                     className="absolute right-2 top-2 cursor-pointer"
//                     size={25}
//                     onClick={() => setVisible(false)}
//                   />
//                 ) : (
//                   <AiOutlineEyeInvisible
//                     className="absolute right-2 top-2 cursor-pointer"
//                     size={25}
//                     onClick={() => setVisible(true)}
//                   />
//                 )}
//               </div>
//             </div>
//             <div className={`${styles.noramlFlex} justify-end `}>
//               <div className="text-sm">
//                 <a
//                   href="/forgot-password"
//                   className="font-medium text-blue-600 hover:text-blue-500"
//                 >
//                   {t("Forgotyourpassword")}?
//                 </a>
//               </div>
//             </div>
//             <div>
//               <LoadingButton isSending={loading} textButton={t("Submit")} />
//             </div>
//             <div
//               className={`${styles.noramlFlex}  w-full justify-center items-center`}
//             >
//               <h4>{t("Nothaveanyaccount")}?</h4>
//               <Link to="/sign-up" className="text-blue-600 pl-2">
//                 {t("SignUp")}
//               </Link>
//             </div>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Login;



import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LoadingButton from "../Layout/LoadingButton";
import backgroundImage from "../../Assests/image/bg.jpeg"; 

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    await axios
      .post(
        `${server}/user/login-user`,
        {
          email,
          password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        setLoading(false);
        toast.success(t("LoginSuccess"));
        navigate("/");
        window.location.reload(true);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <div
    
      className="flex items-center justify-center
       min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="w-full max-w-md bg-white bg-opacity-10 backdrop-blur-lg border border-white/20 p-8 rounded-lg shadow-lg">
        <h2 className="text-center text-2xl font-bold text-white mb-6">
          {t("SignIn")}
        </h2>
        <form className="space-y-5" onSubmit={handleSubmit}>
          <div>
            <label className="block text-sm font-medium text-white">
              {t("Email")}
            </label>
            <input
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              class="w-full px-4 py-3 rounded-lg bg-white bg-opacity-20 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-white">
              {t("Password")}
            </label>
            <div className="relative">
              <input
                type={visible ? "text" : "password"}
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                class="w-full px-4 py-3 rounded-lg bg-white bg-opacity-20 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-white"
              />
              {visible ? (
                <AiOutlineEye
                  className="absolute right-3 top-3 cursor-pointer text-white"
                  size={24}
                  onClick={() => setVisible(false)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  className="absolute right-3 top-3 cursor-pointer text-white"
                  size={24}
                  onClick={() => setVisible(true)}
                />
              )}
            </div>
          </div>
          <div className="flex justify-between text-sm text-white">
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              {t("RememberMe")}
            </label>
            <Link to="/forgot-password" className="no-underline hover:text-black">
              {t("Forgotyourpassword")}?
            </Link>
          </div>
          <LoadingButton isSending={loading}
           textButton={t("login")}  />
          <div className="text-center text-white">
            <span>{t("Nothaveanyaccount")}</span>
            <Link to="/sign-up" className="text-white-400 pl-2 no-underline hover:text-black">
              {t("SignUp")}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;