// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { backend_url } from "../../../server";
// import { useDispatch, useSelector } from "react-redux";
// import { CiHeart } from "react-icons/ci";
// import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
// import { PiShoppingCartSimpleLight, PiEyeLight } from "react-icons/pi";
// import {
//   addToWishlist,
//   removeFromWishlist,
// } from "../../../redux/actions/wishlist";
// import { useEffect } from "react";
// import { addTocart } from "../../../redux/actions/cart";
// import { toast } from "react-toastify";
// import { useTranslation } from "react-i18next";
// import { GetProductName } from "../../../utils/ProductInfo";

// const ProductCard = ({ data, isEvent = false }) => {
//   const { wishlist } = useSelector((state) => state.wishlist);
//   const { cart } = useSelector((state) => state.cart);
//   const [click, setClick] = useState(false);
//   const [open, setOpen] = useState(false);
//   const dispatch = useDispatch();
//   const { i18n } = useTranslation();
//   const ProductName = GetProductName(data, i18n);

//   useEffect(() => {
//     if (wishlist && wishlist.find((i) => i._id === data._id)) {
//       setClick(true);
//     } else {
//       setClick(false);
//     }
//   }, [wishlist, data._id]);

//   const removeFromWishlistHandler = (data) => {
//     setClick(!click);
//     dispatch(removeFromWishlist(data));
//   };

//   const addToWishlistHandler = (data) => {
//     setClick(!click);
//     dispatch(addToWishlist(data));
//   };

//   const addToCartHandler = (id) => {
//     const isItemExists = cart && cart.find((i) => i._id === id);
//     if (isItemExists) {
//       toast.error("Item already in cart!");
//     } else {
//       if (data.stock < 1) {
//         toast.error("Product stock limited!");
//       } else {
//         const cartData = { ...data, qty: 1 };
//         dispatch(addTocart(cartData));
//         toast.success("Item added to cart successfully!");
//       }
//     }
//   };

//   return (
//     <>
//       <div className="group w-[19rem] shadow-sm  h-[24rem] md:w-[15rem] md:h-[20rem] p-3 rounded-md  bg-white justify-between  flex flex-col  ">
//         <div className=" relative w-full h-[70%]  flex justify-center items-center bg-[#F5F5F5] rounded-md ">
//           <Link className="w-[90%] h-[90%]" to={`${`/product/${data._id}`}`}>
//             <img
//               src={`${backend_url}${data.images && data.images[0]}`}
//               width={100}
//               height={100}
//               alt={data.name}
//               className=" w-[100%] h-[100%] object-contain "
//               loading="lazy"
//             />
//           </Link>
//           <div className=" animate-fade-left sm:hidden animate-duration-500  absolute group-hover:flex flex flex-col  right-2 top-2   ">
//             <div className="hover:bg-main group/item1 w-8 mt-2 h-8 flex justify-center items-center rounded-full bg-white ">
//               {click ? (
//                 <CiHeart
//                   className={`${
//                     click ? " text-red-600  " : " text-[#191C1F]  "
//                   }  group-hover/item1:text-red-100 cursor-pointer  `}
//                   size="24"
//                   strokeWidth="0.3"
//                   onClick={() => removeFromWishlistHandler(data)}
//                   title="Add to wishlist"
//                 />
//               ) : (
//                 <CiHeart
//                   className={` group-hover/item1:text-white cursor-pointer  `}
//                   size="24"
//                   strokeWidth="0.3"
//                   onClick={() => addToWishlistHandler(data)}
//                   title="Add to wishlist"
//                 />
//               )}
//             </div>
//             <div className="hover:bg-main group/item2 w-8 mt-2 h-8 flex justify-center items-center rounded-full bg-white ">
//               <PiShoppingCartSimpleLight
//                 className=" group-hover/item2:text-white cursor-pointer"
//                 strokeWidth="1"
//                 size="21"
//                 onClick={() => addToCartHandler(data._id)}
//                 title="Add to cart"
//               />
//             </div>
//             <div className="hover:bg-main group/item3 w-8 mt-2 h-8 flex justify-center items-center rounded-full bg-white ">
//               <PiEyeLight
//                 className=" group-hover/item3:text-white cursor-pointer"
//                 strokeWidth="2"
//                 size="20"
//                 onClick={() => setOpen(!open)}
//                 title="Quick view"
//               />
//             </div>
//           </div>
//         </div>
//         <div className=" flex flex-col justify-around  mt-3">
//           <h1 className="text-[15px] font-[500] capitalize leading-5 text-secondery  mt-2  h-14 ">
//             <Link to={`${`/product/${data._id}`}`}>
//               {ProductName.length > 58
//                 ? ProductName.slice(0, 58) + ".."
//                 : ProductName}
//             </Link>
//           </h1>

//           {data.discountPrice ? (
//             <div className=" flex justify-start  ">
//               <div className=" text-[#929FA5] text-[12px] leading-5 line-through ">
//                 {data.originalPrice}MAD
//               </div>
//               <h4 className=" text-14 font-semibold ml-1   text-main mb-4 leading-5 ">
//                 <meta itemProp="price" content={data.discountPrice} />
//                 {data.discountPrice} MAD
//               </h4>
//             </div>
//           ) : (
//             <h4 className=" text-14 font-semibold text-main mb-4 leading-5 ">
//               <meta itemProp="price" content={data.originalPrice} />
//               {data.originalPrice} MAD
//             </h4>
//           )}
//         </div>
//       </div>

//       {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : ""}
//     </>
//   );
// };

// export default ProductCard;


import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CiHeart } from "react-icons/ci";
import { PiShoppingCartSimpleLight, PiEyeLight, PiShareLight } from "react-icons/pi";
import { addToWishlist, removeFromWishlist } from "../../../redux/actions/wishlist";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetProductName } from "../../../utils/ProductInfo";
import { backend_url } from "../../../server";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";

const ProductCard = ({ data }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const ProductName = GetProductName(data, i18n);

  // Handle Wishlist Toggle
  const toggleWishlist = () => {
    if (click) {
      dispatch(removeFromWishlist(data));
      toast.info("Removed from wishlist");
    } else {
      dispatch(addToWishlist(data));
      toast.success("Added to wishlist");
    }
    setClick(!click);
  };

  // Handle Add to Cart
  const addToCartHandler = () => {
    if (cart.find((i) => i._id === data._id)) {
      toast.error("Item already in cart!");
    } else if (data.stock < 1) {
      toast.error("Product stock limited!");
    } else {
      dispatch(addTocart({ ...data, qty: 1 }));
      toast.success("Item added to cart successfully!");
    }
  };

  // Handle Product Sharing
  const shareProduct = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: data.name,
          text: "Check out this product!",
          url: `${window.location.origin}/product/${data._id}`,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      toast.error("Sharing not supported on this device.");
    }
  };

  return (
    <>
      <div className="flex flex-col items-center w-full max-w-xs">
        <div className="relative w-full aspect-square overflow-hidden rounded-md border border-gray-300">
          <Link to={`/product/${data._id}`}>
            <img
              src={`${backend_url}${data.images && data.images[0]}`}
              alt={data.name}
              className="w-full h-full object-cover"
              loading="lazy"
            />
          </Link>

          {/* Action Buttons */}
          <div className="absolute top-2 right-2 flex flex-col gap-2">
            {/* Wishlist Button */}
            <button className="bg-white p-2 rounded-full shadow-md" onClick={toggleWishlist}>
              <CiHeart size={22} className={click ? "text-red-500" : "text-gray-600"} />
            </button>

            {/* Add to Cart Button */}
            <button className="bg-white p-2 rounded-full shadow-md" onClick={addToCartHandler}> 
              <PiShoppingCartSimpleLight size={22} className="text-gray-600" />
            </button>

            {/* Quick View Button */}
            <button className="bg-white p-2 rounded-full shadow-md" onClick={() => setOpen((prev) => !prev)}>
              <PiEyeLight size={22} className="text-gray-600" />
            </button>

            {/* Share Button */}
            <button className="bg-white p-2 rounded-full shadow-md" onClick={shareProduct}>
              <PiShareLight size={22} className="text-gray-600" />
            </button>
          </div>
        </div>

        {/* Product Name */}
        <h2 className="text-sm font-medium text-gray-700 mt-2 text-center">
          <Link to={`/product/${data._id}`}>{ProductName}</Link>
        </h2>

        {/* Product Price */}
        <p className="text-lg font-semibold text-black">
          {data.discountPrice ? (
            <>
              <span className="line-through text-gray-500 text-sm">{data.originalPrice} MAD</span>{" "}
              {data.discountPrice} MAD
            </>
          ) : (
            `${data.originalPrice} MAD`
          )}
        </p>
      </div>

      {/* Quick View Modal */}
      {open && <ProductDetailsCard setOpen={setOpen} data={data} />}
    </>
  );
};

export default ProductCard;