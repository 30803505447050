import React from "react";
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Sponsored from "../components/Route/Sponsored";
import Footer from "../components/Layout/Footer";
import Blog from "../components/Blog/Blog"
import Cate from "../components/categories/cate";
import MakersSection from "../components/MakersSection/MakersSection";
import StatsSection from "../components/StatsSection/StatsSection";

const HomePage = () => {
  return (
    <div className="  ">
      <Header activeHeading={1} />
      <Hero />
      <Sponsored />
      <Cate />
      <MakersSection/>
      <br />
      <StatsSection/>

      <FeaturedProduct />
      <Blog/>
      <Footer />
    </div>
  );
};

export default HomePage;
